var mobileMenuBtn = document.querySelector('.header__menu'),
    mobileMenuClose = document.querySelector('.mobile-nav__control-close'),
    mobileMenu = document.querySelector('.mobile-nav'),
    main = document.querySelector('main'),
    footer = document.querySelector('footer'),
    header = document.querySelector('.header__inner');

mobileMenuBtn.addEventListener('click', function() {
    mobileMenu.classList.add('mobile-nav_showed');
    main.classList.add('blured');
    header.classList.add('blured');
    if(footer != null)
        footer.classList.add('blured');
})
mobileMenuClose.addEventListener('click', function() {
    mobileMenu.classList.remove('mobile-nav_showed');
    main.classList.remove('blured');
    header.classList.remove('blured');
    if(footer != null)
        footer.classList.remove('blured');
})
document.addEventListener('click', function(e) {
    if(mobileMenu.classList.contains('mobile-nav_showed') && e.target == mobileMenu) {
        mobileMenu.classList.remove('mobile-nav_showed');
        main.classList.remove('blured');
        header.classList.remove('blured');
        if(footer != null)
            footer.classList.remove('blured');
    }
});